.login{

    max-width: 100%;
    min-width: 100%;

    padding:5px;
    -webkit-box-shadow: 10px 10px 65px 0px #fea952;
    -moz-box-shadow: 10px 10px 65px 0px #fea952;
    box-shadow: 10px 10px 65px 0px #fea952;

}
.login .remember{
    text-align: center;
}
.login label{
    color: #FFF;
}

.h1-nologin{
    color: #FFF !important;
}

.cookies{

    width:100%;

    margin:0 auto;
    padding-left:1%;
    padding-top:5px;
    font-size: 1.2em;
    clear:both;
    font-weight: bold;
    color: #FFF;
    bottom:0px;
    position:fixed;
    left: 0px;
    background-color: #000000;
    opacity:0.85;
    filter:alpha(opacity=70); /* For IE8 and earlier */
    transition: bottom 1s;
    -webkit-transition:bottom 1s; /* Safari */
    -webkit-box-shadow: 3px -3px 1px rgba(50, 50, 50, 0.56);
    -moz-box-shadow: 3px -3px 1px rgba(50, 50, 50, 0.56);
    box-shadow: 3px -3px 1px rgba(50, 50, 50, 0.56);
    z-index:999999999;
    font-family: OpenSansSemibold;
    text-align:center;
}

.cookies a{
    color: #fea952;
}
.cookies .input{
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    box-shadow: none;
    margin-bottom: 1em;
    text-shadow: none;
    color: #FFF;
    border: 1px solid #f5a623;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;
    width: auto;
    padding-left:5px;
    padding-right: 5px;

}
#construction{
    font-size: 36px;
}


.background-alpha-home{
    background-color: #ffffff !important;
    color: #000101;
    min-height:425px;
    padding-top:20px;
}
.background-alpha-home-long{
    background-color: #ffffff !important;
    color: #000101;
    min-height:800px;
    padding-top:20px;
}
.titlehome{
    font-family: OpenSansBold !important;
    font-size: 45px !important;
    line-height: 36px !important;
    color: #003366;
    font-weight: bold;
}

.subtitlehome{
    font-family: OpenSansbold !important;
    font-size: 30px !important;
    line-height: 36px !important;
    color: #003366;
    font-weight: bold;
}

.texthome{
    font-family: OpenSansSemibold;
    font-size: 20px !important;
    line-height: 36px !important;
    color: #000000;
}

div.round-image {
    /* cambia estos dos valores para definir el tamaño de tu círculo */

    height:300px;

    background-repeat: no-repeat;

    background-size: 80% auto;

    margin:auto
}
div.big-line {
    /* cambia estos dos valores para definir el tamaño de tu círculo */
    width:  100%;
    border-radius:100px;
    border:5px solid #fb8f2c;
    margin-bottom:30px;
}

div.big-line2 {
    /* cambia estos dos valores para definir el tamaño de tu círculo */
    width:  100%;
    border-radius:100px;
    border:5px solid #cb3210;
    margin-bottom:30px;
}

div.big-line3 {
    /* cambia estos dos valores para definir el tamaño de tu círculo */
    width:  100%;
    border-radius:100px;
    height:10px;
    margin-bottom:30px;
    background: rgba(203,50,16,1);
    background: -moz-linear-gradient(left, rgba(203,50,16,1) 0%, rgba(251,144,44,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(203,50,16,1)), color-stop(100%, rgba(251,144,44,1)));
    background: -webkit-linear-gradient(left, rgba(203,50,16,1) 0%, rgba(251,144,44,1) 100%);
    background: -o-linear-gradient(left, rgba(203,50,16,1) 0%, rgba(251,144,44,1) 100%);
    background: -ms-linear-gradient(left, rgba(203,50,16,1) 0%, rgba(251,144,44,1) 100%);
    background: linear-gradient(to right, rgba(203,50,16,1) 0%, rgba(251,144,44,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cb3210', endColorstr='#fb902c', GradientType=1 );

}

.border-image{

    border-radius:100%;
    -webkit-box-shadow: 10px 10px 51px 6px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 51px 6px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 51px 6px rgba(0,0,0,0.75);
}
.image-back{
    top:-50px;
    z-index: 50;
}
.always-front{
    z-index:999;
}
.img-arrow{
    left: 60%;
    top: 97px;
    position: relative;

}




