

#logo-home-nologin{
    background-image: url("../../../public/assets/images/header/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    height:101px;



}

#introduction{
    display:inline;
    font-family: OpenSansRegular;
    font-weight: bold;
    line-height: 30px;
    color:#FFF;
    max-width:541px;

}

#sub_title {
    background-color: #513e3a;
    border: 0 none;
    float: left;
    height: 2px;
    position: relative;
    width: 400px;
    top: -20px;
    clear:both;
}
#arrow{
    position:relative;
    top:15px;
    left:-40px;
    float:right;
}


.call_to_action {
    background-color: #FF8F2C;
    border-style: outset;
    border-width: 1px;
    color: #fff;
    cursor: pointer;
    font-family: OpenSansCondbold;
    font-size: 31px;
    #left: 140px;
    padding: 20px;
    text-align: center;
    margin-top:40px;
    width: 217px;
    display:block;
    margin-bottom:50px;
}
.call_to_action a{
    color: #fff;
}
#_submit{
    background-color: #ff8f2cb0;
    border-style: outset;
    border-width: 1px;
    color: #fff;
    cursor: pointer;
    font-family: OpenSansCondbold;
    font-size: 20px;
    #left: 140px;

    text-align: center;
    margin-top:5px;
    width: 217px;
    display:block;
    margin-bottom:5px;
}
.login>a{
    color: #093366;
    background-color:#ffffffb3;

}


/**** BLOG *******/
.bg-image {
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    background-image: url(https://4.bp.blogspot.com/-D1mZ2rQcyvU/Xq2k1eB368I/AAAAAAAAACg/ZzWUjK8-sZshcfvQRluuYXrGdMYOhj16gCK4BGAYYCw/s0/backgroundbasketball.png);
    min-height: 300px;
    width:100%;
}
.title-blog{
    font-family: OpenSansBold !important;
    line-height: 36px !important;
    color: #f5f5f5;
    text-align: center;
    font-weight: bold;
}
.title-nomobile{
    font-size: 61px;
}
.subtitle-blog{
    font-family: OpenSans !important;
    font-size: 20px !important;
    line-height: 36px !important;
    color: #eee;
    font-weight: bold;
    text-align: center;
}
.subtitle3-blog{
    font-family: OpenSans !important;
    font-size: 15px !important;
    line-height: 36px !important;
    color: #eee;
    font-weight: bold;
    text-align: center;
    order: 1;
}
.subtitle-highlight-blog{
    color:#f6ab5b!important;
}
.aside-blog{
    background-color: #04032e;
    min-height: 100vh;
}
.container-light{
    background-color:#FFF;
 }
.blog-tag{
    border-radius: 25px;
    background: #003e5f;
    padding: 8px;
    width: 30px;
    height: 10px;
    color: #f5f5f5;
}
.text-blog{
    color: #757575;
    font: 15px Roboto, sans-serif;
    line-height: 1.6em;
    margin: 1.5em 0 2em 0;
    display: block;
}
.img-blog-index{
    width:100%;
}
.post-title {
    font: bold 28px Roboto, sans-serif;
    float: left; margin: 0 0 8px 0;
    max-width: calc(100% - 48px);
    color: #212121
}
.flat-button, .flat-button:hover {
    position: relative;
    cursor: pointer;
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 2px;
    padding: 8px;
    margin: -8px;
    text-align: right;
    color: #2196f3;
    text-decoration: none;
}